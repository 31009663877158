<template>
  <v-card>
    <v-card-title class="">
      <a href="https://api.pettycashglobal.com/static/excel/prospects.xlsx">
        <v-btn
            color="success"
            class="me-3 mt-3 "
            small
        >
            <font-awesome-icon icon="fa-solid fa-download" class="pe-1" />
            Excel Template
        </v-btn>
      </a>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn x-small elevation="10" class="text-xs font-weight-semibold" color="info darken-2"
        :to="{ name: 'savings.add_marketing_prospects' }">
        <font-awesome-icon icon="fa-solid fa-plus" class="pe-1" />
        Add New Prospect List
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="prospectList"
      item-key="id"
      class="table-rounded"
      :items-per-page="10"
      disable-sort
      :search="search"
    >
      <!-- name -->
      <template #[`item.name`]="{item}">
        <div class="d-flex flex-column" @click="getProspectInfo(item.id)" style="cursor: pointer">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
          <small><b>Uploaded By:</b> {{ item.uploader }}</small>
        </div>
      </template>
      <template #[`item.get_date_str`]="{item}">
        {{ item.get_date_str }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'

export default {
  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Pending: 'primary',
      Validated: 'success',
      Cancelled: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'List Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      prospectList: [],
      statusColor,
      search: '',

      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      this.getProspectsList();
      document.title = "TopUp | Prospected Sections"
  },

  methods:{
    getProspectsList(){
      axios
        .get('/api/v1/topup/get/prospects_list/')
        .then(response => {
          this.prospectList = response.data  // get the data and fill into descriptions
          console.log(this.prospectList)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    getProspectInfo(info_id){
      let router = this.$router
      router.push('/savings/marketing/view/prospects/'+info_id)
    }
  },
}
</script>
